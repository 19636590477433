












































































































































































import Vue from 'vue';
import { message } from 'ant-design-vue';
import * as api from '@/api/appShelf';
import moment from 'moment';
import EditableCell from './EditableCell.vue';
import { toNumber } from 'lodash';

export default Vue.extend({
  name: 'appShelfList',
  components: {
    EditableCell,
  },
  data() {
    return {
      listLoading: false,
      searchForm: {
        shelfName: '',
        shelfTime: [],
        shelfStatus: '1',
      },
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          this['pagination'].current = page;
          this['pagination'].pageSize = pageSize;
          this['getAppShelfList']();
        },
        onShowSizeChange: (current, size) => {
          this['pagination'].current = current;
          this['pagination'].pageSize = size;
          this['getAppShelfList']();
        },
      },
      sortPagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          this['sortPagination'].current = page;
          this['sortPagination'].pageSize = pageSize;
          this['sortSearch']();
        },
        onShowSizeChange: (current, size) => {
          this['sortPagination'].current = current;
          this['sortPagination'].pageSize = size;
          this['sortSearch']();
        },
      },
      sortVisible: false,
      sortSearchForm: {
        shelfName: '',
      },
      columns: [
        {
          title: '货架名称',
          dataIndex: 'prodName',
          scopedSlots: { customRender: 'prodName' },
        },
        {
          title: '排序值(1-999,999最靠前显示)',
          dataIndex: 'spuSort',
          width: '380px',
          scopedSlots: { customRender: 'spuSort' },
        },
      ],
      dataSource: [],
      spuSortList: [] as any,
      sortLoading: false,
    };
  },
  mounted() {
    this.getAppShelfList();
    this.sortSearch();
  },
  methods: {
    moment,
    createProdStatus(prodStatus: string) {
      if (prodStatus == '1') {
        return '上架中';
      } else if (prodStatus == '2') {
        return '已下架';
      } else if (prodStatus == '3') {
        return '资源已删除';
      } else {
        return '已过期';
      }
    },
    getStartTime(time) {
      if (time) {
        return moment(time).startOf('day').valueOf();
      } else {
        return '';
      }
    },
    getEndTime(time) {
      if (time) {
        return moment(time).endOf('day').valueOf();
      } else {
        return '';
      }
    },
    getAppShelfList() {
      this.listLoading = true;
      const startTime = this.getStartTime(this.searchForm.shelfTime[0]);
      const endTime = this.getEndTime(this.searchForm.shelfTime[1]);
      api
        .getAppShelfList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.shelfName,
          startTime,
          endTime,
          this.searchForm.shelfStatus ? this.searchForm.shelfStatus : ''
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getAppShelfList();
    },
    createApp() {
      this.$router.push({
        path: '/operate/appShelf/create',
      });
    },
    titleClick(id: string) {
      this.$router.push({
        path: '/operate/appShelf/details',
        query: { id: id },
      });
    },
    //货架排序弹框打开
    AppSorting() {
      this.getAppShelfList();
      this.sortVisible = true;
    },
    //货架排序搜索
    sortSearch() {
      this.spuSortList = [];
      this.sortLoading = true;
      const startTime = '';
      const endTime = '';
      const prodStatus = '1';
      const orderField = 'SPU_SORT';
      const order = 'desc';
      api
        .sortList(
          this.sortPagination.current,
          this.sortPagination.pageSize,
          this.sortSearchForm.shelfName,
          startTime,
          endTime,
          prodStatus,
          orderField,
          order
        )
        .then((res) => {
          if (res.status === 200) {
            this.sortPagination.total = res.result.total;
            this.dataSource = res.result.list;
            this.dataSource.map((obj) => {
              this.spuSortList.push(
                obj['spuSort'] === null ? 0 : obj['spuSort']
              );
            });
          }
        })
        .finally(() => {
          this.sortLoading = false;
        });
    },
    //货架排序重置
    sortReset() {
      api.resetSort().then((res) => {
        if (res) {
          this.sortSearch();
        }
      });
    },
    cancel() {
      console.log('cancel');
    },
    change(record, value) {
      const newValue = toNumber(value);
      let sortFlag = false;
      for (let index = 0; index < this.spuSortList.length; index++) {
        if (newValue == this.spuSortList[index]) {
          sortFlag = true;
        }
      }
      if (sortFlag) {
        if (newValue === 0) {
          return;
        } else {
          this.$message.error('此数字已存在，不可重复输入！');
        }
        sortFlag = false;
      } else {
        const params = {
          sequenceNbr: record.sequenceNbr,
          version: record.version,
          spuSort: value,
        };
        api.editSort(params).then((res) => {
          if (res.status === 200) {
            this.sortSearch();
          }
        });
      }
    },
  },
});
